
import { defineComponent } from 'vue'
import ButtonClose from '@/components/base/buttons/ButtonClose.vue'
export default defineComponent({
  components: {
    ButtonClose,
  },

  props: {
    message: {
      type: String,
      default: '',
    },
    icon: {
      type: [Object, Function],
      default: undefined,
    },
    classIcon: {
      type: [String, Array],
      default: '',
    },
  },
  emits: ['close'],
})
