<template>
  <div
    class="gap-4 flex hover:shadow-md justify-between transition duration-200 items-center shadow rounded-full bg-gray-200 py-2 px-3"
  >
    <span>
      <component :is="icon" class="mr-2" :class="classIcon" />
      <span class="text-sm">{{ message }} </span>
    </span>

    <button-close
      class="rounded-full w-6 h-6 bg-white p-1 shadow flex items-center"
      @click="$emit('close')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ButtonClose from '@/components/base/buttons/ButtonClose.vue'
export default defineComponent({
  components: {
    ButtonClose,
  },

  props: {
    message: {
      type: String,
      default: '',
    },
    icon: {
      type: [Object, Function],
      default: undefined,
    },
    classIcon: {
      type: [String, Array],
      default: '',
    },
  },
  emits: ['close'],
})
</script>
